













import { Content, Header, Nav, Sidebar } from "@/components/Layouts";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "Layout3",
  components: {
    Header,
    Content,
    Sidebar,
    Nav,
  },
})
export default class extends Vue {
  flag = 127;

  created() {
    this.onNavBarShow(this.$store.state.Menu.menuShowFlg);
  }

  @Watch("$store.state.Menu.menuShowFlg")
  onNavBarShow(value: number) {
    this.flag = value;
  }
}
