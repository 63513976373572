// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    icon: null,
    idx: "cCenter",
    meta: {},
    name: "经销商评估",
    path: "/",
    openType: 1,
    isLink: false,
  },
];
export default menus;
