// 应用路由
const routers = [
  {
    path: "",
    name: "certificationCenter",
    meta: { hidden: false },
    component: () => import("@/views/certificationCenter/index.vue"),
    // children: [
    //   {
    //     path: "demo",
    //     name: "demo",
    //     meta: { hidden: true },
    //     component: () => import("@/views/demo.vue"),
    //   },
    // ],
  },
];
export default routers;
